@import './variables.scss';

@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap');

html, body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;

    font-family: 'Courier Prime', monospace;
}

html {
    font-size: 24px;
}

input, button {
    font-family: 'Courier Prime', monospace;
    font-size: 24px;
}

@media(max-width: 800px) {
    html, input, button {
        font-size: 16px;
    }
}

#root {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div, p, ul, ol, li, figure, blockquote, header, h1 {
    padding: 0;
    margin: 0;
}

p {
    margin: .7em;
    line-height: 1.5em;
}

body {
    background: url('/assets/background.png') 50% 50% no-repeat;
    background-size: cover;
    background-attachment: fixed ;
}

.error-info {
    color: red;
    text-align: center;
    padding: .5em;
}

p.talk-phase {
    &:before {
        content: ' - '
    }

    padding-left: 1.2em;
    text-indent: -1.2em;
}

.mainHeader {
    width: 0px;
    height: 0px;
    text-indent: -9999px;
}
